import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-icons/font/bootstrap-icons.css"

import * as Vue from 'vue'
import App from './App.vue'
import { API, HTTP } from './api';
import router from './router'



const app = Vue.createApp(App)

API.interceptors.response.use({},
    error => {
        if (error.response.status == 401 || error.response.status == 419) {
            const token = localStorage.getItem('token');
            if (token) {
                localStorage.removeItem('token');
            }

            const user = localStorage.getItem('user');
            if (user) {
                localStorage.removeItem('user');
            }
            window.location.reload()
        } else {
            if (error.response.status == 403) {
                router.push('/');
            }
        }


        return Promise.reject(error);
    });

HTTP.interceptors.response.use({}, error => {
    if (error.response.status == 401 || error.response.status == 419) {
        const token = localStorage.getItem('token');
        if (token) {
            localStorage.removeItem('token');
        }

        const user = localStorage.getItem('user');
        if (user) {
            localStorage.removeItem('user');
        }
        window.location.reload()
    } else {
        if (error.response.status == 403) {
            router.push('/');
        }
    }

    return Promise.reject(error);
});

app.config.globalProperties.API = API
app.config.globalProperties.HTTP = HTTP

app.use(router)

app.mount('#app')

import "bootstrap/dist/js/bootstrap.js"
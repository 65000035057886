import axios from 'axios';


const API = axios.create({
    baseURL: `https://remind.urbanstore.uz/api/api`,
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },

});

const HTTP = axios.create({
    baseURL: `https://remind.urbanstore.uz/api`,
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});

// const API = axios.create({
//     baseURL: `http://localhost:8000/api`,
//     withCredentials: true,
//     headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//     },

// });

// const HTTP = axios.create({
//     baseURL: `http://localhost:8000`,
//     withCredentials: true,
//     headers: {
//         'Accept': 'application/json',
//         'Content-Type': 'application/json',
//     },
// });



export { API, HTTP }